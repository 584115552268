import React from 'react';
import styled from 'styled-components';
import K from '../K';
import { Link } from 'react-router-dom';


const Ul = styled.ul`

    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    background-color: ${K.Theme.primary4};
    color: ${K.Theme.secondary};
    align-items: center;
    padding: 30px 0px;
    width: 100%;
    height: 100px;

    li {

        padding: 0px 10px;   

        :hover {
            color: ${K.Theme.primary}
        }

    }

    button {
        background-color: ${K.Theme.primary};
        color: ${K.Theme.secondary};
        padding: 15px 10px;
        border: 0px;
        border-radius: 5px;
        transition: all 0.3s linear;

        :hover {
            background-color: ${K.Theme.secondary};
            color: ${K.Theme.primary};
            cursor: pointer;
        }

    }

    @media (max-width: 768px) {

        flex-flow: column nowrap;
        /* padding-top: 4rem; */
        height: auto;

        li {

            color: ${K.Theme.secondary};
            padding: 10px 10px;
        }

    } 

    .navlink{
        text-decoration: none;
        color: inherit;
    }

`


const Footer = ({ open }) => {

    function onClickService() {

        var elmnt = document.getElementById("services");
        if (elmnt != null) {
            elmnt.scrollIntoView()
        }

        if (window.localStorage) {
            window.localStorage["scroll"] = true;
        }
    
        
    }

    return (

        <Ul open = {open}>


            <li><Link to={K.Routes.Home} className="navlink">STARTSEITE</Link></li>
            <li onClick={() => onClickService()}><Link to={K.Routes.Home} className="navlink">LEISTUNGEN</Link></li>
            <li><Link to={K.Routes.AccidentReportTab} className="navlink">UNFALLRATGEBER</Link></li>
            <li><Link to={K.Routes.Imprint} className="navlink">IMPRESSUM</Link></li>
            <li><Link to={K.Routes.Privacy} className="navlink">DATENSCHUTZ</Link></li>

            <button><Link to={K.Routes.Contact} className="navlink">KONTAKT</Link></button>



            <button onClick={()=>window.scrollTo(0, 0)} style={{ transform: "rotate(90deg)", right:"20px", position: "absolute"}}>«</button>

        </Ul>

    );

}

export default Footer;