import React, { Component } from 'react';
import Map from "./Map"
import { Grid } from '@material-ui/core';
import ContactForm from './ContactForm';

class ContactGrid extends Component {

    render() {
        return (
            <Grid container justify="center" spacing={3} style={{marginBottom: "30px"}}> 

                {this.props.isContact ? 

                    <Grid item style={{width: '100%'}}>
                        <h1>Nehmen Sie jetzt Kontakt auf</h1>
                        <ContactForm> </ContactForm>
                    </Grid> : 
                    <Grid item className="grid-item">
                        <h1>Hier können Sie uns finden</h1>
                        <Map isContact={this.props.isContact}></Map>
                    </Grid>

                }

                {this.props.isContact ? 
                    
                    <Grid item style={{width: '100%'}}>
                        <h1>Hier können Sie uns finden</h1>
                        <Map isContact={this.props.isContact}></Map>
                    </Grid>:
                    <Grid item className="grid-item">
                        <h1>Nehmen Sie jetzt Kontakt auf</h1>
                        <ContactForm> </ContactForm>
                    </Grid>  
                   
                }

            </Grid>
        );
    }
}

export default ContactGrid;