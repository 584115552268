import { createMuiTheme } from '@material-ui/core/styles';

class K {

    static muiTheme = createMuiTheme({
        palette: {
          primary: {
            main: '#d47e02',
          },
          secondary: {
            main: '#FFFFFF',
          },
        },
    })

    static Theme = class {

        static primary = "#d47e02"
        static secondary = "#ffffff"
        static primary2 = "#000000"
        static primary3 = "#fcb90f"
        static primary4 = "#323437"

    }

    static Routes = class {

        static Home = "/Home"
        static AccidentReport = "/AccidentReport"
        static CostEstimate = "/CostEstimate"
        static RepairConfirmation = "/RepairConfirmation"
        static Appraisal = "/Appraisal"
        static Evidence = "/Evidence"
        static Contact = "/Contact"
        static Imprint = "/Imprint"
        static AccidentReportTab = "/AccidentReportTab"
        static Privacy = "/Privacy"

    }
    static ToastNotification = class {
        static limit = 3
        static duration = 3 * 1000
    }

}

export default K;