import React, { Component } from 'react';
import styled from 'styled-components';
import costEstimatePng from './../../Assets/Images/services/costEstimate.png'
import costEstimateJPG from  './../../Assets/Images/services/Kostenvoranschlag.jpg'
import K from '../K'

const CostEstimateDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        padding: 10px;
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};
            font-size: 3rem;


        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;

            img {

                align-self: center;
                height: 20vh;
                width: 20vh;

            }
            h1 {

                line-height: normal;
                color: ${K.Theme.secondary};
                text-align: center;
                font-size: 2rem;

            }

        }

    }

    .content-div{

        margin: 10px 300px;
        text-align: left;

        h2 {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 10px;
            margin-left: 50px;
            color: ${K.Theme.primary};
            
        }

        p {
            
            font-size: 1.5rem;

        }

        img {

            float: right;   
            width: 25vw;
            height: 30vh;
            margin-left: 10px;
            margin-bottom: 10px;
        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }

        @media (max-width: 768px) {

            margin: 30px 10px;

            img {
                width: 100%;
            }

        }
    }

`

class CostEstimate extends Component {
    componentDidMount() {

        window.scrollTo(0, 0)
        
    }
    render() {
        return (
            <CostEstimateDiv>
                <div className="title-div">
                    <img src = {costEstimatePng} alt="broken" />
                    <h1>Kostenvoransclag</h1>
                </div>

                <div className="content-div"> 
                    <div>
                        <h1> 
                            Die Reparaturkalkulation dient ausschließlich zur Ermittlung der
                            Kosten zur Behebung des Schadens. 
                        </h1>
                        <br />
                        <img src = {costEstimateJPG} alt="broken" />
                        <br />
                        <p>
                            Die Reparaturkalkulation sollte einem Gutachten nur dann vorgezogen werden,
                            wenn sofort erkennbar ist, dass die Schadenshöhe unter 750,-€ liegt, eine
                            Wertminderung auszuschließen ist, da dies bei einer Reparaturkalkulation nicht
                            ermittelt wird, des Weiteren Verkehrssicherheit, Reparaturdauer,
                            Nutzungsausfälle o.ä. keine Relevanz haben. Dies wird ebenfalls bei einer
                            Reparaturkalkulation nicht berücksichtigt oder man eine
                            Reparaturkostenausweitung ausschließen kann.
                        </p>
                    
                        
                    </div>
                </div>
            
            </CostEstimateDiv>
        );
    }
}

export default CostEstimate;