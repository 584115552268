import React, { Component } from 'react';
import ServicesGrid from './ServicesGrid';
import styled from 'styled-components';
import ContactGrid from './ContactGrid'

const HomeDiv = styled.div`
    text-align: center;
    margin: 50px 100px;
    /* width:100%; */

    p {
        font-size: 1.5rem;
        margin-bottom: 50px;
    }

    h1 {
        font-size: 3.5rem;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {

        margin: 100px 10px;

        p {
            font-size: 1.5rem;
            margin-bottom: 30px;
        }

        
        h1 {
            font-size: 2rem;
            margin-bottom: 10px;
        }
    }
    
    .grid-item {

        width: 50%;

        @media (max-width: 768px) {

            width: 100%;
            height: 300px;
            margin-bottom: 60px;

        }

        h1 {

            font-size: 2rem;
            margin-bottom: 20px;

        }

    }

`

class Home extends Component {
    

    componentDidMount() {
        if (window.localStorage["scroll"] == 'true') {

            var elmnt = document.getElementById("services");
            if (elmnt != null) {
                elmnt.scrollIntoView()
            }
            window.localStorage["scroll"] = false

        } else {

            window.scrollTo(0, 0)

        }

    }

    render() {
        return (
            <HomeDiv>

                <h1>
                    Unfall gehabt? Wir helfen Ihnen!
                </h1>

                <p style={{marginBottom:0}}>
                    Sie sind in einen Unfall verwickelt worden oder brauchen ein Gutachten für Ihr Fahrzeug? 
                </p>
                <br/>
                <p>
                    Als Kfz-Ingenieurbüro in Berlin, bieten wir Ihnen stets seriöse und kompetente Hilfe an, im Bereich der Unfallschadensbegutachtung und Fahrzeugbewertung. Wir sind unseren Kunden gegenüber freundlich und bieten Ihnen die richtige Unterstützung in einem Schadenfall an.
                    <br /><br />
                    Wir bieten zuverlässige Beratung und schnelle Bearbeitung Ihrer Anliegen. Hierbei bieten wir unseren Vor-Ort-Service. Überall in Berlin und Umgebung an. Die Erstellung des Gutachtens erfolgt gewöhnlich innerhalb von 24 Stunden. Des Weiteren sind wir Ihnen bei der Konsultation der richtigen Anwälte gerne behilflich.

                </p>
                
                <div id ="services">
                    <h1>
                        Unsere Leistungen
                    </h1>
                    <ServicesGrid/>
                </div>

                <h1 id="Contact us">
                    Kontaktieren Sie uns!
                </h1>

                <ContactGrid />

            </HomeDiv>
        );
    }
}

export default Home;