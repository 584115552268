import React, { Component } from 'react';
import styled from 'styled-components';
import appraisalPng from './../../Assets/Images/services/valueReport.png'
import appraisalJPG from  './../../Assets/Images/services/Wertgutachten1.PNG'
import K from '../K'

const AppraisalDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        padding: 10px;
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};
            font-size: 3rem;

        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;

            img {

                align-self: center;
                height: 20vh;
                width: 20vh;

            }
            h1 {

                line-height: normal;
                color: ${K.Theme.secondary};
                text-align: center;
                font-size: 2rem;

            }

        }

    }

    .content-div{

        margin: 10px 300px;
        text-align: left;

        h2 {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 10px;
            margin-left: 50px;
            color: ${K.Theme.primary};
            
        }

        p {
            
            font-size: 1.5rem;

        }

        img {

            float: right;   
            width: 25vw;
            height: 30vh;
            margin-left: 10px;
        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }

        @media (max-width: 768px) {

            margin: 30px 10px;

            img {
                width: 100%;
            }

        }
    }

`

class Appraisal extends Component {
    componentDidMount() {

        window.scrollTo(0, 0)
        
    }
    render() {
        return (
            <AppraisalDiv>
                
                <div className="title-div">
                    <img src = {appraisalPng} alt="broken" />
                    <h1>Wertgutachten</h1>
                </div>

                <div className="content-div"> 

                    <h1> 
                        Möchten Sie sich ein Fahrzeug kaufen oder einen Oldtimer versichern?
                    </h1>
                    <br />
                    <p>
                        Hierfür erstellen wir für Sie ein unabhängiges Wertgutachten.
                    </p>
                    <br/>
                    <p>
                        Das KFZ Ingenieurbüro Kaplan bietet Ihnen den Service, in und um Berlin täglich für Sie unterwegs zu sein und Ihnen bei der Erstellung eines Wertgutachtens zu helfen.
                    </p>
                    <br/>
                    <div>
                        
                        <h1> 
                            Wann brauchen Sie eine professionelle Fahrzeugbewertung?
                        </h1>
                        <br />
                        <img src = {appraisalJPG} alt="broken" />
                        <br />
                        <p>
                            Eine professionelle Fahrzeugbewertung ermittelt den Wert des Fahrzeugs, unter Berücksichtigung von Schäden und Mängeln. Nur ein geschultes Expertenauge kann dies in einem Wertgutachten für Sie festhalten.
                            <br/><br/>
                            Ein Wertgutachten wird dann dringend notwendig, wenn Sie es zur Finanzierung eines Gebrauchtwagens benötigen, als Nachweis für das Finanzamt, bei Beendigung eines Leasingvertrages oder zur Ermittlung des Versicherungstarifes.
                        </p>
                        <br/>
                        <h1> 
                            Welche Informationen enthält das KFZ-Wertgutachten?
                        </h1>
                        <br/>
                        <ul>
                            <li>Wiederbeschaffungswert</li>
                            <br/>
                            <li>Zeitwert</li>
                            <br/>
                            <li>Restwert</li>
                            <br/>
                            <li>Händlereinkaufswert</li>
                        </ul>
                        <br/>
                        <p>
                            Nachträglich eingebautes Zubehör oder Reparaturen, die den Wert erhöhen, werden ebenfalls berücksichtigt. Ebenso fließen regionale Faktoren wie z.B. Verfügbarkeit in die Bewertung mit ein.
                        </p>
                    </div>
                </div>
            
            </AppraisalDiv>
        );
    }
}

export default Appraisal;