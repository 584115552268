import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Div = styled.div `

    height: 300px;
    width: 340px;
    /* position: relative; */
 
    img {

        height: 200px;
        width: 200px;
        transition: all 0.2s linear;

        :hover {

            height: 230px;
            width: 230px;
            opacity: 0.7;

        }
        
    } 

    a {
        text-decoration: none;
        color: inherit;
    }

    @media (max-width: 768px) {

        width: 330px;

    }
    
`

const GridButton = (props) => {

    return (
        <Div>
            <Link to={props.route}>
            <img src={props.src} alt="broken"/>
            <h2>{props.name}</h2>
            </Link>

        </Div>
    );
}

export default GridButton;