import React, { Component } from 'react';
import styled from 'styled-components';
import K from '../K'
import orderPng from './../../Assets/Images/order.png'
import policePng from './../../Assets/Images/police.png'
import selfiePng from './../../Assets/Images/selfie.png'

const AccidentReportTabDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        padding: 10px;
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};
            font-size: 3rem;


        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;

            img {

                align-self: center;

            }
            h1 {

                line-height: 15vh;
                color: ${K.Theme.secondary};
                text-align: center;
                font-size: 2rem;

            }

        }

    }

    .content-div{

        margin: 50px 300px;

        h2 {
            
            color: ${K.Theme.primary};
            
        }

        p {
            
            font-size: 1.5rem;

        }

        img {

            width: 25vw;
            height: 30vh;
            margin-left: 10px;
        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }
        div{
            
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            /* justify-content: center; */
            align-items: center;
            padding: 10px;

            img {

                height: 200px;
                width: 210px;
                padding-right: 10px;
                border-right: 4px solid ${K.Theme.primary};

            }
            h1 {

                line-height: 30vh;
                color: ${K.Theme.secondary};
                font-size: 3rem;

            }
            
            p {
                
                padding-left: 10px;

            }

            @media (max-width: 768px) {

                flex-flow: column nowrap;
                margin-top: 0px;

                img {

                    align-self: center;
                    width: 200px;
                    padding-right: 0px;
                    border-right: none;

                }
                h1 {

                    line-height: normal;
                    color: ${K.Theme.secondary};
                    text-align: center;

                }
                p {
                
                    padding-left: 0px;

                }

            }

        }
        @media (max-width: 768px) {

            margin: 30px 10px;
            text-align: left;

            img {
                width: 100%;
            }

        }
    }

`

class AccidentReportTab extends Component {
    componentDidMount() {

        window.scrollTo(0, 0)
        
    }
    render() {
        return (
            <AccidentReportTabDiv>
                
                <div className="title-div">
                    <h1>UNFALLBERATER</h1>
                </div>
                    <div className="content-div"> 
                        <h2> 
                            1. Sicherung des Unfallstelle. 
                        </h2>
                        <div>
                        <img src = {orderPng} alt="broken" />
                        <p>
                            Machen Sie Ihr Warndreieck sichtbar für
                            andere Treiber und schalten Sie die
                            Warnleuchten. In der Veranstaltung
                            von geringfügigen Schäden sofort
                            Fahren Sie das Fahrzeug aus der Gefahrenzone.
                        </p>
                    </div>
                    </div>
                    <div className="content-div"> 
                        <h2> 
                            2. Informieren Sie die Rettungskräfte
                        </h2>
                        <div>
                        <img src = {policePng} alt="broken" />
                        <p>
                            Rufen Sie die Polizei (110). Wenn es 
                            Verletzte geben sollte, dann auch die 
                            Feuerwehr (112).
                        </p>
                        </div>
                    </div>
                    <div className="content-div"> 
                        <h2> 
                            3. Dokumentation der Unfallstelle
                        </h2>
                        <div>
                        <img src = {selfiePng} alt="broken" />
                        <p>
                            Machen Sie Fotos von Schdden am Kfz, 
                            dem Kfz Kennzeichen und nehmen Sie 
                            die Personcllien auf.
                        </p>
                        </div>
                    </div>
            
            </AccidentReportTabDiv>
        );
    }
}

export default AccidentReportTab;