import React, { Component } from 'react';
import styled from 'styled-components';
import K from '../K'
import imprintTitle from './../../Assets/Images/imprintTitle.png'

const ImprintDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: none;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        padding: 10px;
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};

        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;
            display: flex;

            img {

                align-self: center;

            }
            h1 {

                /* line-height: normal; */
                line-height: 15vh;
                color: ${K.Theme.secondary};
                text-align: center;

            }

        }

    }

    .content-div{

        margin: 30px 300px;
        text-align: justify;

        /* display: flex;
        align-items: center;
        justify-content: center; */

        h2 {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 10px;
            margin-left: 50px;
            color: ${K.Theme.primary};
            
        }

        h3 {
            display: inline-block;
        }

        p {
            
            font-size: 1.5rem;

        }

        img {

            float: right;   
            width: 25vw;
            height: 30vh;
            margin-left: 10px;
        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }
        div {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 20px;
        }

        @media (max-width: 768px) {

            margin: 30px 10px;
            text-align: left;

            img {
                width: 100%;
            }

        }
    }

`

class Imprint extends Component {
    componentDidMount() {

        window.scrollTo(0, 0)
        
    }
    render() {
        return (
            <ImprintDiv>
                
                <img src={imprintTitle} className="imgHeader" alt="broken"/>
                <div className="title-div">
                    <h1>IMPRESSUM</h1>
                </div>
                <div className="content-div"> 
                    <div>
                        <br/>
                        <h3>Rechtsform:</h3> Einzelunternehmen
                        <br/><br/>
                        <h3>Steuernummer:</h3> 13/373/03078
                        <br/><br/>
                        <h3>Umsatzsteuer-ID:</h3> DE321776426
                        <br/><br/>
                        <h3>Telefon:</h3> 017623851994
                        <br/><br/>
                        <h3>Mail:</h3> info@kfz-gutachten-kaplan.de
                        <br/><br/>
                        <h3>Finanzamt:</h3> Finanzamt Charlottenburg-Wilmersdorf
                        <br/><br/>
                        <h3>Ansprechpartner/Inhaber:</h3> B.SC. Sabri Kaplan
                        <br/>
                        <br/>
                    </div>
                </div>
            
            </ImprintDiv>
        );
    }
}

export default Imprint;