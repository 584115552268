import React, { Component } from 'react';
import styled from 'styled-components';
import evidencePng from './../../Assets/Images/services/Evidence.png'
import evidenceJPG from  './../../Assets/Images/services/Beweissicherung.jpg'
import K from '../K'

const EvidenceDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        padding: 10px;
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};
            font-size: 3rem;
            font-size: 2.5rem;

        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;

            img {

                align-self: center;
                height: 20vh;
                width: 20vh;

            }
            h1 {

                line-height: normal;
                color: ${K.Theme.secondary};
                text-align: center;
                font-size: 2rem;

            }

        }

    }

    .content-div{

        margin: 10px 300px;
        text-align: left;

        h2 {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 10px;
            margin-left: 50px;
            color: ${K.Theme.primary};
            
        }

        p {
            
            font-size: 1.5rem;

        }

        img {

            float: right;   
            width: 25vw;
            height: 30vh;
            margin-left: 10px;
            margin-bottom: 10px
        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }

        @media (max-width: 768px) {

            margin: 30px 10px;

            img {
                width: 100%;
            }

        }
    }

`

class Evidence extends Component {
    componentDidMount() {

        window.scrollTo(0, 0)
        
    }
    render() {
        return (
            <EvidenceDiv>
                
                <div className="title-div">
                    <img src = {evidencePng} alt="broken" />
                    <h1>Beweissicherung</h1>
                </div>

                <div className="content-div"> 
                    <div>
                        
                        <h1> 
                            Eine Unfallsituation ist nicht immer ganz eindeutig, daher steht nicht
                            immer sofort fest, wer der Schuldige ist. 
                        </h1>
                        <br />
                        <img src = {evidenceJPG} alt="broken" />
                        <br /><br />
                        <p>
                            Öfters wird der Schuldige erst vor Gericht festgestellt.
                            <br/><br/>
                            Könnens Sie jedoch so lange auf Ihr Fahrzeug verzichten? Wir bieten Ihnen an,
                            den Schadenstand zu dokumentieren, so dass Sie Ihr Fahrzeug unabhängig von
                            der Klärung der Schuldfrage reparieren lassen und wieder nutzen können. Damit
                            besitzen Sie alle Unterlagen für das Gericht.
                            <br/><br/>
                            Der Kfz Gutachter Halil Aydin steht Ihnen gerne zur Seite.
                        </p>
                    </div>
                </div>
            
            </EvidenceDiv>
        );
    }
}

export default Evidence;