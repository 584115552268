import React from 'react';
import styled from 'styled-components';
import K from './../K';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Link } from 'react-router-dom';


const Ul = styled.ul`

    list-style: none;
    display: flex;
    flex-flow: row nowrap;

    li {

        padding: 18px 10px;
        transition: all 0.3s linear;

        :hover {
            color: ${K.Theme.primary};
            cursor: pointer;
        }

    }

    .dropbtn {

        background-color: transparent;
        color: ${K.Theme.primary2};
        padding: 18px 10px;
        font-size: 1rem;
        border: none;
        
        transition: all 0.3s linear;

    }

        /* The container <div> - needed to position the dropdown content */
    .dropdown {

        position: relative;
        display: inline-block;
    }

        /* Dropdown Content (Hidden by Default) */
    .dropdown-content {

        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;

    }

        /* Links inside the dropdown */
    .dropdown-content a {

        color: ${K.Theme.primary2};
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        transition: all 0.3s linear;

        :hover { 
            
            color: ${K.Theme.primary};

        }

    }

        /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {

        display: block;

        @media (max-width: 960px) {

            display: none;

        }

    }

        /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropdown:hover .dropbtn {

        color: ${K.Theme.primary};
        cursor: pointer;

    }

    @media (max-width: 960px) {

        flex-flow: column nowrap;
        background-color: ${K.Theme.primary};
        position: fixed;
        transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
        top: 0;
        right: 0;
        height: 100vh;
        width: 250px;
        padding-top: 4rem;
        transition: 0.3s ease-in-out;

        li {

            color: ${K.Theme.secondary};
            padding: 8px 10px;

            :hover {
                color: ${K.Theme.primary4};
                cursor: pointer;
            }
        }

        .dropbtn {

            color: ${K.Theme.secondary};
            padding: 10px 10px;

        }

        .dropdown:hover .dropbtn {

            color: ${K.Theme.primary4};
            cursor: pointer;

        }

    } 

    .tooltip {

        @media (max-width: 960px) {
            
            padding-top: 20px;

        }

    }

    .tooltip .tooltiptext {

        /* visibility: none; */
        display: none;
        /* width: 120px; */
        background-color: ${K.Theme.primary};
        color: ${K.Theme.secondary};
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        font-size: 1rem;
        white-space: nowrap;

        /* Position the tooltip */
        position: absolute;
        margin-top: 30px;
        right: 0px;
        z-index: 1;

        @media (max-width: 960px) {

            display: block;
            background-color: transparent;
            margin-top: 28px;
            white-space: pre-wrap;
            right: auto;

        }

    }

    .tooltip:hover .tooltiptext {

        display: block;

    }

    .expanded-div {
        /* border-left: 1px solid ${K.Theme.secondary}; */
        margin-left: 40px;

        li {

            padding: 5px 5px;
            /* margin-left: 40px; */

        } 

        li a {
            text-decoration: none;
            color: ${K.Theme.secondary};

            :hover {
                color: ${K.Theme.primary4};
            }
        }
    }
    
    .navlink {
        text-decoration: none;
        color: inherit;
        
    }

`

const RightNav = (props) => {
    
    const color = props.open ? "secondary": "primary"

    function onClickService() {

        setExpanded(!expanded)
        if (!props.open) {

            var elmnt = document.getElementById("services");
            if (elmnt != null) {
                elmnt.scrollIntoView()
            }

            if (window.localStorage) {
                window.localStorage["scroll"] = true;
            }

        }
        
        
    }
    
    function onClickItem() {
        props.setOpen(false)
        var elem = document.getElementById("phonecall")
        // console.log(elem)
        if (elem) {
            elem.style.position = "absolute"
        }
    }

    var [expanded, setExpanded] = React.useState(false)

    return (

        <Ul open = {props.open}>

            <li onClick={()=>onClickItem()}><Link to={K.Routes.Home} className="navlink">STARTSEITE</Link></li>

            <div class="dropdown">
                <button class="dropbtn" onClick={() => onClickService()}>
                    
                    
                    
                    <Link to={K.Routes.Home} className="navlink">LEISTUNGEN▼</Link>
                    
                    
                    </button>
                <div class="dropdown-content">
                    <Link onClick={()=>onClickItem()} to={K.Routes.AccidentReport}>Unfallgutachten</Link>
                    <Link onClick={()=>onClickItem()} to={K.Routes.CostEstimate}>Kostenvoranschlag</Link>
                    <Link onClick={()=>onClickItem()} to={K.Routes.RepairConfirmation}>Reparaturbest</Link>
                    <Link onClick={()=>onClickItem()} to={K.Routes.Appraisal}>Wertgutachten</Link>
                    <Link onClick={()=>onClickItem()} to={K.Routes.Evidence}>Beweissicherung</Link>
                </div>
            </div>

            {expanded && props.open ?
            
                <div className="expanded-div">
                    <li onClick={()=>onClickItem()}><Link to={K.Routes.AccidentReport} >Unfallgutachten</Link></li>
                    <li onClick={()=>onClickItem()}><Link to={K.Routes.CostEstimate}>Kostenvoranschlag</Link></li>
                    <li onClick={()=>onClickItem()}><Link to={K.Routes.RepairConfirmation}>Reparaturbest</Link></li>
                    <li onClick={()=>onClickItem()}><Link to={K.Routes.Appraisal}>Wertgutachten</Link></li>
                    <li onClick={()=>onClickItem()}><Link to={K.Routes.Evidence}>Beweissicherung</Link></li>
                </div> : null
        
        
            }

            <li onClick={()=>onClickItem()}><Link to={K.Routes.AccidentReportTab} className="navlink">UNFALLRATGEBER</Link></li>
            <li onClick={()=>onClickItem()}><Link to={K.Routes.Imprint} className="navlink">IMPRESSUM</Link></li>
            <li onClick={()=>onClickItem()}><Link to={K.Routes.Contact} className="navlink">KONTAKT</Link></li>

            <hr />

            <ThemeProvider theme = {K.muiTheme}>

                  
                <IconButton className = "tooltip" color = {color} onClick={()=>{window.open("https://www.instagram.com/kfz.kaplan/")}}>
                    <Link to="#" onClick={()=>onClickItem()} className="navlink">
                        <InstagramIcon />
                    </Link>
                    <span class="tooltiptext">kfz.kaplan</span> 
                </IconButton>

                <IconButton className = "tooltip" color = {color}>
                    <Link to={K.Routes.Contact} onClick={()=>onClickItem()} className="navlink">
                        <MailOutlineIcon />
                    </Link>
                    <span class="tooltiptext">info@kfz-gutachten-kaplan.de</span>
                </IconButton>

                <IconButton className = "tooltip" color = {color}>
                    {
                        props.open? <a href="tel:017623851994" onClick={()=>onClickItem()} className="navlink">
                                <PhoneIcon />
                            </a>:
                            <Link to="#" onClick={()=>onClickItem()} className="navlink">
                                <PhoneIcon />
                            </Link>
                    }
                    
                    <span class="tooltiptext">017623851994</span>
                </IconButton>

                <IconButton className = "tooltip" color = {color}>
                    <Link to={K.Routes.Contact} onClick={()=>onClickItem()} className="navlink">
                        <LocationOnIcon />
                    </Link>
                    <span class="tooltiptext">Kaiserin-Augusta-Allee-89 in 10589 Berlin</span>
                </IconButton>

            </ThemeProvider>

        </Ul>

    );

}

export default RightNav;