import React from 'react';
import TextField from '@material-ui/core/TextField';
import K from '../K';
import axios from "axios"
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Box from '@material-ui/core/Box'
import { Tick } from 'react-crude-animated-tick';
import emailjs from "emailjs-com";
 


const Form = styled.form `
    

    .grid-input {

        width: 100%;
        /* margin: 10px 0px; */
    } 

    button {

        background-color: ${K.Theme.primary};
        color: ${K.Theme.secondary};
        display: flex;
        justify-content: center;
        /* width: calc(100%); */
        border: none;
        padding: 15px 10px;
        border-radius: 5px;
        font-size: 1rem;
        transition: all 0.3s linear;
        bottom: 0;
        position: absolute;
        /* margin: 5px; */

        :hover {
            background-color: ${K.Theme.primary4};
            cursor: pointer;
        }
        
    }
 
`



export default function ContactForm() {

    var [data] = React.useState({

        "Ihr Name": "", 
        "Ihre Email": "", 
        "Betreff": "", 
        "Ihre Nachricht": "", 

    })

    let templateParams = {
        name: "",
        email: "",
        subject: "",
        message: "",
      };

    var [isValid, setIsValid] = React.useState(0)

    function sendEmail() {

        templateParams.name = data['Ihr Name']
        templateParams.email = data['Ihre Email']
        templateParams.subject = data.Betreff
        templateParams.message = data['Ihre Nachricht']

        emailjs
        .send("service_o4q7g38", "template_uvoodzp", templateParams, "user_tAGebkbnTM9Pmf942kqSx")
        .then(
            (result) => {
            console.log(result.text);
            toast.success("Email sent successfully!");
            setIsValid(2)
            },
            (error) => {
            console.log(error.text);
            toast.error("Email not sent!");
            setIsValid(0)
            }
        );
        
        /*axios.post("/SendEmail", data)
        .then(response => {

            // console.log(response)

            setIsValid(2)

        })*/

    }
    function isEmail (email) {

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());

    }

    function validate() {

        for (const [key, value] of Object.entries(data)) {

            if (value.length === 0) {

                toast.error(key.toUpperCase() + " kann nicht leer sein")
                return
                
            }
            if (key === "Ihre Email" && !isEmail(value)) {
                toast.error(key.toUpperCase() + " falsches Format")
                return
            }
    
        }

        setIsValid(1)
        sendEmail()

    }

    function updateValue(key, value) {

        data[key] = value
    
    }

    function decideDiv(){

        if (isValid === 1) {

            return <>
                <div class="loader"></div>
                <h2 style={{ color: K.Theme.primary4, border: "none", margin: "20px 10px", textAlign:"center", fontSize:"1.2rem" }}>
                    Senden...
                </h2>
            </>

        } else if (isValid === 0) {

            return <Form noValidate autoComplete="off" style = {{ textAlign: "left" }} >

                <TextField id="standard-basic" label="Ihr Name"  onChange={(event) => updateValue("Ihr Name", event.target.value)} className="grid-input"/>
                <TextField id="standard-basic" label="Ihre Email" onChange={(event) => updateValue("Ihre Email", event.target.value)} className="grid-input"/>
                <TextField id="standard-basic" label="Betreff" onChange={(event) => updateValue("Betreff", event.target.value)} className="grid-input"/>
                <TextField
                    onChange={(event) => updateValue("Ihre Nachricht", event.target.value)}
                    id="standard-multiline-static"
                    label="Ihre Nachricht"
                    multiline
                    rows={5}
                    style={{ width: '100%', margin:"10px 0px" }}

                />

                <button
                    type='button'
                    onClick={ () => validate() }
                >
                    NACHRICHT SENDN
                </button>
            </Form>
 
        } else if (isValid === 2) {

            return <>
                <div>
                    <Tick size={150} />
                    <h2 style={{ color: K.Theme.primary4, border: "none", margin: "0px", textAlign:"center", fontSize:"1.2rem" }}>Danke, dass sie uns kontaktiert haben! Wir werden uns in Kürze mit Ihnen in Verbindung setzen.</h2>
                    
                    </div>
                <button className="styled-button" onClick={() => setIsValid(0)}> fortsetzen </button>
            </>
        }

    }

    return (

        <Box display="flex" flexDirection="column"
            width="100%"
            height= "calc(100% - 60px)"
            justifyContent="center"
            position="relative"
            marginBottom="110px"
            // bgcolor = {K.Theme.secondary} borderRadius={5} border={"3px solid " + K.Theme.primary} style={{opacity:'0.8'}}
            >

            {decideDiv()}


        </Box>

        
    );
}