// import React from "react";
import React, { Component } from 'react';
// import { compose, withProps } from "recompose";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup
} from "react-leaflet"

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// const Map = compose(
//   withProps({
//     /**
//      * Note: create and replace your own key in the Google console.
//      * https://console.developers.google.com/apis/dashboard
//      * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
//      */
//     googleMapURL:
//       "https://maps.googleapis.com/maps/api/js?key=AIzaSyD0gcxiMQYETiUgZ8qFBEkGm_6u7tP0dWE&v=3.exp&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `400px` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   withScriptjs,
//   withGoogleMap
// )((props) => (
//   <GoogleMap defaultZoom={8} defaultCenter={{ lat: 52.525537, lng: 13.309315 }}>
//     {props.isMarkerShown && (
//       <Marker position={{ lat: 52.525537, lng: 13.309315 }} />
//     )}
//   </GoogleMap>
// ));

class Map extends Component {
    render() {
        return (
            <MapContainer id="location" center={[52.525537, 13.309315]} zoom={13} scrollWheelZoom={false} style={{ height: this.props.isContact?"30vh": "100%" }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                <Marker position={[52.525537, 13.309315]}>
                <Popup>
                    Kaiserin-Augusta-Allee-89 in 10589 Berlin
                </Popup>
                </Marker>
            </MapContainer>
        )
    }
}

export default Map;
