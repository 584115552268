import React, { Component } from 'react';
import styled from 'styled-components';
import ContactGrid from './../Main/ContactGrid'
import K from '../K'

const ContactDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        padding: 10px;
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};

        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;

            img {

                align-self: center;

            }
            h1 {

                line-height: 15vh;
                color: ${K.Theme.secondary};
                text-align: center;

            }

        }

    }

    .content-div{

        margin: 10px 300px;

        h2 {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 10px;
            margin-left: 50px;
            color: ${K.Theme.primary};
            
        }

        p {
            
            font-size: 1.5rem;

        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }

        @media (max-width: 768px) {

            margin: 30px 10px;
            text-align: left;

            img {
                width: 100%;
            }

        }
    }

`

class Contact extends Component {
    componentDidMount() {

        window.scrollTo(0, 0)
        
    }
    render() {
        return (
            <ContactDiv>
                
                <div className="title-div">
                    <h1>KONTAKT</h1>
                </div>

                <div className="content-div"> 
                    
                    <ContactGrid isContact = {true}/>
                </div>
            
            </ContactDiv>
        );
    }
}

export default Contact;