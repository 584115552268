import React, { Component } from 'react';
import styled from 'styled-components';
import accidentReportPng from './../../Assets/Images/services/accident.png'
import accidentReportJpg from './../../Assets/Images/services/Unfallgutachten.jpg'
import K from './../K'

const AccidentReportDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};
            font-size: 3rem;

        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;

            img {

                align-self: center;
                height: 20vh;
                width: 20vh;

            }
            h1 {

                line-height: normal;
                color: ${K.Theme.secondary};
                text-align: center;
                font-size: 2rem;

            }

        }

    }

    .content-div{

        margin: 10px 50px;
        text-align: left;
        background-color: ${K.Theme.secondary};
        padding:20px 200px;
        h2 {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 10px;
            margin-left: 50px;
            color: ${K.Theme.primary};
            
        }

        p {
            
            font-size: 1.5rem;

        }

        img {

            float: right;   
            width: 25vw;
            height: 30vh;
            margin-left: 10px;
        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }

        @media (max-width: 768px) {
            padding:20px 20px;
            margin: 30px 10px;
            text-align: left;

            img {
                width: 100%;
            }

        }
    }

`

class AccidentReport extends Component {

    componentDidMount() {

        window.scrollTo(0, 0)
        
    }

    render() {
        return (
            <AccidentReportDiv>
                
                <div className="title-div">
                    <img src = {accidentReportPng} alt="broken" />
                    <h1>Unfallgutachten</h1>
                </div>

                <div className="content-div"> 
                    <h1> Sind Sie der Geschädigte in einem Unfall? </h1>
                    <br/>
                    <p>
                        Wenn ja, dann liegt ein Haftpflichtschaden vor, der im gesamten Umfang vom
                        Unfallverursacher getragen werden muss. Laut dem Urteil des BGH gilt :
                    </p>
                    <br/>
                    <h2>
                        ❝… der Schaden muss eine Höhe von mindestens 750€ aufweisen, um eine
                        Erstattung des Gutachtens zu erhalten. …❞
                    </h2>
                    <br/>
                    <div>
                        <img src = {accidentReportJpg} alt="broken" />
                        <p>
                            Sollte der Schaden den Wert nicht erreichen, bieten wir Ihnen als Alternative die Reparaturkosten-Kalkulation an.
                            <br/><br/>
                            Als die Geschädigte Partei ..
                        </p>    
                        <br/>
                        <ul>
                            <li>
                                haben Sie das Recht: in Mannheim, sich einen Sachverständigen zur Erstellung
                                eines KFZ-Gutachtens frei zu wählen. Laut §249 BGB stehen Sie da, als ob der
                                Unfall nicht passiert wäre.
                            </li>
                            <br/>
                            <li>
                                Sie besitzen den Anspruch auf einen Mietwagen oder eine Entschädigung für
                                den Nutzungsausfall.
                            </li>
                            <br/>
                            <li>
                                Ihnen steht es frei, ob Sie den Schaden zur Reparatur bringen oder Sie sich
                                den von ermittelten Betrag des Unfallschadens, exklusive der
                                Mehrwertsteuer, auszahlen lassen wollen
                            </li>
                        </ul>
                        <br/>
                        <h1> Was tun, wenn ein Kaskoschaden vorliegt? </h1>
                        <br/>
                        <p>
                            Im Falle eines Schadens der durch Sie selbst, oder durch ein anderes versichertes
                            Ereignis eingetreten ist, liegt die Weisungsberechtigung beim Versicherer. Die
                            Wahl des Gutachters muss demnach in Absprache mit dem Versicherer erfolgen.
                            <br/><br/>
                            Wir als KFZ-Gutachter in Mannheim bieten Ihnen stets ausgiebige Hilfestellungen an.
                            Rufen Sie uns an und lassen Sie sich Ihr Unfallgutachten innerhalb von 24 Stunden
                            von uns erstellen.
                        </p>
                        
                    </div>


                </div>
            
            </AccidentReportDiv>
        );
    }
}

export default AccidentReport;