import React, { Component } from 'react';
import styled from 'styled-components';
import repairConfirmationPng from './../../Assets/Images/services/repairConfirmation.png'
import repairConfirmationJPG from  './../../Assets/Images/services/Reparaturbestätigung.jpg'
import K from '../K'

const RepairConfirmationDiv = styled.div`
    min-height: calc(100vh - 175px);
    .title-div {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        background-color: ${K.Theme.primary3};
        padding: 10px;
        margin-top: 75px;

        img {

            height: 30vh;
            width: 300px;

        }
        h1 {

            line-height: 30vh;
            color: ${K.Theme.secondary};
            font-size: 3rem;

        }

        @media (max-width: 768px) {

            flex-flow: column nowrap;

            img {

                align-self: center;
                height: 20vh;
                width: 20vh;

            }
            h1 {

                line-height: normal;
                color: ${K.Theme.secondary};
                text-align: center;
                font-size: 2rem;

            }

        }

    }

    .content-div{

        margin: 10px 300px;
        text-align: left;

        h2 {
            
            border-left: 5px solid ${K.Theme.primary};
            padding-left: 10px;
            margin-left: 50px;
            color: ${K.Theme.primary};
            
        }

        p {
            
            font-size: 1.5rem;

        }

        img {

            float: right;   
            width: 25vw;
            height: 30vh;
            margin-left: 10px;
        }

        ul {

            list-style: none;
            margin-left: 8ch;
        }

        li {
            font-size: 1.5rem;
            
        }
        li::before {
            
            content: "•"; 
            color: ${K.Theme.primary};
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        
        }

        @media (max-width: 768px) {

            margin: 30px 10px;
            text-align: left;

            img {
                width: 100%;
            }

        }
    }

`

class RepairConfirmation extends Component {
    componentDidMount() {

        window.scrollTo(0, 0)
        
    }
    render() {
        return (
            <RepairConfirmationDiv>
                
                <div className="title-div">
                    <img src = {repairConfirmationPng} alt="broken"/>
                    <h1>Reparaturbestätigung</h1>
                </div>

                <div className="content-div"> 
                    <div>
                        <h1> 
                            Die meisten Versicherungen verlangen eine Reparaturbestätigung als
                            Nachweis zur Schadenregulierung.    
                        </h1>
                        <br />
                        <img src = {repairConfirmationJPG} alt="broken\" />
                        <br />
                        <p>
                            Dies wird genutzt als Überprüfung der durchgeführten Reparaturen an einem
                            Unfallfahrzeug. Wenn es um ausstehende Beträge, oder die Regulierung des
                            Nutzungsausfalls geht, wird die Reparaturbestätigung meistens verlangt.

                            Um Probleme zu vermeiden, empfehlen wir Ihnen immer eine
                            Reparaturbestätigung an die Versicherer zu senden. Es kann vorteilhaft sein einen

                            Nachweis über die getätigte Reparatur zu besitzen. Im Fall einer erneuten
                            Schadensabwicklung, besitzen Sie einen Beweis über zuvor getätigte Reparaturen.
                        </p>
                    
                        
                    </div>
                </div>
            
            </RepairConfirmationDiv>
        );
    }
}

export default RepairConfirmation;