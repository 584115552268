import React from 'react';
import styled from 'styled-components';
import K from "./../K"
import Burger from './Burger';
import { useHistory } from 'react-router-dom';

const Nav = styled.nav`

    width: 100%;
    height: 75px;
    border-bottom: 2px solid ${K.Theme.primary};
    padding: 10px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top:0;
    background-color: ${K.Theme.secondary};
    z-index: 1001;

    .logo {

        width: 15%;
        height: 100%;
        cursor: pointer;

    }

    .logoMobile {

        width: calc(100% - 3rem);
        height: 100%;
        padding: 5px;
        display: none;
        cursor: pointer;
    }

    @media (max-width: 960px) {
            
        padding: 0;
        background-color: ${K.Theme.primary2};
        /* position: relative; */
        
        .logoMobile {

            display: block;

        }

        .logo {

            display: none;
            
        }

    }

`

const Navbar = (props) => {

    const history = useHistory();

    return (

        <Nav>
            
            <img src={require("./../../Assets/Images/logoWhite.png")} onClick={()=>history.push(K.Routes.Home)} alt="" className="logo"/>
            <img src={require("./../../Assets/Images/logoBlack.png")} onClick={()=>history.push(K.Routes.Home)} alt="" className="logoMobile"/>
            <Burger />

        </Nav>

    );

}

export default Navbar;