import { Grid } from '@material-ui/core';
import GridButton from './GridButton'
import React from 'react';
import unfallgutachten from './../../Assets/Images/services/unfallgutachten.png'
import Kostenvoranschlag from './../../Assets/Images/services/Kostenvoranschlag.png'
import Reparaturbestätigung from './../../Assets/Images/services/Reparaturbestätigung.png'
import Wertgutachten from './../../Assets/Images/services/Wertgutachten.png'
import Beweissicherung from './../../Assets/Images/services/Beweissicherung.png'
import K from './../K'

const ServicesGrid = () => {

    const services1 = {

        "Unfallgutachten": [unfallgutachten, K.Routes.AccidentReport],
        "Kostenvoranschlag": [Kostenvoranschlag, K.Routes.CostEstimate],
        "Reparaturbest": [Reparaturbestätigung, K.Routes.RepairConfirmation]
        
    }

    const services2 = {

        "Wertgutachten": [Wertgutachten, K.Routes.Appraisal],
        "Beweissicherung": [Beweissicherung, K.Routes.Evidence]
        
    }

    return (
        <>
            <Grid container justify="center" style={{marginBottom: "20px"}}>

                {
                    Object.entries(services1).map(([key, value])  =>

                        <Grid item > 
                            <GridButton src={value[0]} name={key} route={value[1]}/>
                        </Grid>
                    )
                }

            </Grid>
            <Grid container justify="center" style={{marginBottom: "20px"}}>

            {
                Object.entries(services2).map(([key, value])  =>

                    <Grid item > 
                        <GridButton src={value[0]} name={key} route={value[1]}/>
                    </Grid>
                )
            }

            </Grid>
        </>
    );

}

export default ServicesGrid;