import React from 'react';
import Navbar from './Nav/Navbar';
import styled from 'styled-components';
import {BrowserRouter as Router, Switch, Route, Redirect } from'react-router-dom'
import Home from './Main/Home';
import AccidentReport from './Services/AccidentReport';
import K from './K'
import Footer from "./Footer/Footer"
import CostEstimate from './Services/CostEstimate';
import RepairConfirmation from './Services/RepairConfirmation';
import Appraisal from './Services/Appraisal';
import Evidence from './Services/Evidence';
import Contact from './Tabs/Contact'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Imprint from './Tabs/Imprint';
import AccidentReportTab from './Tabs/AccidentReportTab'

import phoneImg from './../Assets/Images/phone.png'

import { Helmet } from 'react-helmet';
import icon from './../Assets/Images/icon.png'

import Privacy from './../Components/Tabs/Privacy'


const MainDiv = styled.div`

    .imgHeader {

        margin-top: 75px;
        width: 100%;
        height: 35vh;

        @media (max-width: 768px) {

            display: none;

        }

    }
    .styled-button {

        background-color: ${K.Theme.primary};
        color: ${K.Theme.secondary};
        display: flex;
        justify-content: center;
        width: calc(100% - 10px);
        border: none;
        padding: 10px;
        border-radius: 5px;
        font-size: 1rem;
        transition: all 0.3s linear;
        margin: 5px 5px;
        bottom: 0;
        position: absolute;

        :hover {
            background-color: ${K.Theme.primary4};
            cursor: pointer;
        }
    }

    .loader {

        font-size: 10px;
        text-indent: -9999em;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: ${K.Theme.primary};
        background: -moz-linear-gradient(left, ${K.Theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
        background: -webkit-linear-gradient(left, ${K.Theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
        background: -o-linear-gradient(left, ${K.Theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
        background: -ms-linear-gradient(left, ${K.Theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
        background: linear-gradient(to right, ${K.Theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
        position: relative;
        -webkit-animation: load3 0.5s infinite linear;
        animation: load3 0.5s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);

    }

    .loader:before {

        width: 50%;
        height: 50%;
        background: ${K.Theme.primary};
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';

    }

    .loader:after {

        background: ${K.Theme.secondary};
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

    }

    @-webkit-keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .aPhone {
        display: none;

        @media (max-width: 768px) {

            
            display: block;
            z-index: 1001;
            background-color: green;
            padding: 10px;
            border-radius: 50%;
            position: absolute;
            bottom: 5px;
            right: 5px;
            list-style-type:none;
            img {
                width:30px;
                height: 30px;
            }
        }

    }

`

function App() {

    React.useEffect(() => {

        document.addEventListener('scroll', () => {
            var elem = document.getElementById("phonecall")
            if (elem) {
                elem.style.position = "fixed"
            }
        });

    })
    return (
        <Router>
            <Helmet>
                <link rel="shortcut icon" href={icon} />
                <title>KFZ Ingenieurbüro Kaplan</title>
            </Helmet>
            <MainDiv>
                <Navbar />
                <ToastContainer 
                        limit={K.ToastNotification.limit} 
                        autoClose={K.ToastNotification.duration}
                        role="dark"
                />
                <Switch>

                    <Route exact path={K.Routes.Home}>

                        <img src={require("./../Assets/Images/header.png")} className="imgHeader" alt="broken"/>
                        <Home />

                    </Route>

                    <Route path={K.Routes.AccidentReport} component={AccidentReport} />
                    <Route path={K.Routes.CostEstimate} component={CostEstimate} />
                    <Route path={K.Routes.RepairConfirmation} component={RepairConfirmation} />
                    <Route path={K.Routes.Appraisal} component={Appraisal} />
                    <Route path={K.Routes.Evidence} component={Evidence} />
                    
                    <Route path={K.Routes.AccidentReportTab} component={AccidentReportTab} />
                    <Route path={K.Routes.Imprint} component={Imprint} />
                    <Route path={K.Routes.Contact} component={Contact} />

                    <Route path={K.Routes.Privacy} component={Privacy} />

                    

                </Switch>
                <Footer/>
                <a href="tel:017623851994" className="aPhone" id="phonecall"><img src= {phoneImg} alt="broken"/>  </a>
            </MainDiv>
            <Redirect to={K.Routes.Home}></Redirect>
        </Router>
    );

}

export default App;
